@import "../helpers/helpers.scss";

.product-details {

  .product-stats {
    //display: flex;
    //justify-content: space-between;
    //align-items: center;
    margin-bottom: 50px;
  }

  .product-description {
    margin: 50px 0;
    border-top:1px solid #eee;
    border-bottom:1px solid #eee;
    padding:20px 0;
    h3 {
      text-align:left;
      font-weight:bold;
      margin-bottom:10px;
    }
    h3:empty {
      display:none;
    }
    p {
      font-size: 1.125em;
      color: #818181;
      margin-bottom: 25px;
      line-height: 1.4;
    }
  }

  .product-tags {
    display: flex;
    flex-wrap: wrap;
  }

  .product-tag {
    margin-right: 40px;
    margin-bottom: 20px;
    color: $brand;
    font-weight: 600;
    font-family: "Quicksand", sans-serif;
  }

  .product-info-circles {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    list-style: none;
  }

  .info-circle {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    text-align: center;
    font-family: "Quicksand", sans-serif;
    width: 50%;

    p {
      font-size: 0.875em;
      padding-bottom:10px;
    }

    svg, label {
      color: $brand;
      width:70px;
      height:70px;
      margin-bottom: 20px;
    }
  }

  .circle {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 70px;
    width: 70px;
    border-radius: 50%;
    background-size: 56px 56px;
    background-position: center;
    background-color: $brand;
    margin-bottom: 20px;
    svg {
      height:42px;
      width:42px;
      color:#fff;
      margin-bottom:0;
    }
    .fa-grapes {
      height:38px;
      width:38px;
      padding-left:3px;
      margin-top:-3px;
    }

    .fa-wheat {
      height:38px;
      width:38px;
    }




    &.abv {
      background: url("../../assets/abv.svg") no-repeat;
      background-color: $brand;
    }
    &.ab-cert {
      background: url("../../assets/ab_cert.svg") no-repeat;
      background-color: $brand;
    }
    &.bio {
      background: url("../../assets/biodinamic.svg") no-repeat;
      background-color: $brand;
    }
    &.organic {
      background: url("../../assets/organic.svg") no-repeat;
      background-color: $brand;
    }
    &.origin {
      background: url("../../assets/origin.svg") no-repeat;
      background-color: $brand;
    }
    &.screw-cap {
      background: url("../../assets/screw_cap.svg") no-repeat;
      background-color: $brand;
    }
    &.terra-vitis {
      background: url("../../assets/terra_vitis.svg") no-repeat;
      background-color: $brand;
    }
    &.vegan {
      background: url("../../assets/vegan.svg") no-repeat;
      background-color: $brand;
    }
    &.vintage {
      background: url("../../assets/vintage.svg") no-repeat;
      background-color: $brand;
    }
    &.winemaker {
      background: url("../../assets/winemaker.svg") no-repeat;
      background-color: $brand;
    }

    svg, label {
      color: #fff;
    }
  }

  .fa-solid {
    color: white;
  }

  .fa-3x {
    font-size: 2.5em;
  }



  @include m-max($sm) {

    .product-info {
      width: 100%;
    }

    .download-icon-cont {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
    }

    .product-img {
      width: 100%;
      margin-right: 0;
      flex-direction: row;
      align-items: normal;
      justify-content: flex-start;

      a {
        font-size: 1em;
      }

      img {
        margin-right: 50px;
      }
    }



    .info-circle {
      width: 25%;
    }

    .update-cart-btn {
      margin-bottom: 0;
      padding-bottom: 8px;
    }

  }

  @include m-min($sm) {

    .product-info {
      width: 100%;
    }

    .download-icon-cont {
      display: flex;
      flex-direction: column;
      align-items: center;

      .download-icon {
        margin-bottom: 10px;
      }
    }

    .product-img {
      width: 100%;
      flex-direction: row;
      justify-content: flex-start;
      margin-bottom: 50px;

      img {
        margin-right: 140px;
        margin-bottom: 0;
      }
    }

    .product-flex {
      flex-direction: column;
    }


    .update-cart-btn.disabled.update-cart-product-list-table p {
      margin-bottom: 0;
    }

    .info-circle {
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
      width: 20%;

      p {
        font-size: 0.875em;
      }
    }

  }

  @include m-min($md) {

    .product-flex {
      flex-direction: row;
    }

    .product-img {
      width: 20%;
      flex-direction: column;

      img {
        margin-right: 0;
        margin-bottom: 50px;
      }
    }

    .product-info {
      width: 60%;
    }

    .download-icon-cont a {
      text-align: center;
    }

    .print_icon {
      width: 100%;
      display: block;
      text-align: center;
    }

  }

  @include m-min($lg) {

    .update-cart-btn.disabled {
      p {
        margin-bottom: 0;
      }
    }

  }


}

@include m-max($md) {
  .update-cart-btn {
    margin-top: 0;
  }
}

@media print {
  .App {
    .content {
      padding: 0 15px;
    }

    .print_icon {
      display: none;
    }

    .product-list-item_wrapper {
      align-items: center;
      justify-content: center;
    }

    .product-list-item_inner.input {
      display: none;
    }

    .product-list-item-input-label {
      display: block;
      margin-bottom: 0;
    }

    .product-list-item_left {
      width: 200px;

      img {
        height: initial;
        display: block;
      }
    }

    .product-list-item_right {
      display: initial;
      width: initial;
    }

    .product-details .info-circle {
      width: 20%;
      padding-right: 0;
      padding-left: 0;
      margin-right: 0;
    }

    .header {
      .mobile-hamburger-menu {
        display: none;
      }

      .nav-menu {
        display: none;
      }
    }

    .customer-profile {
      display: none;
    }
  }
}
