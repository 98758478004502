@import "../helpers/helpers.scss";

.product-list-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  padding: 10px 0;
  border-top: 1px solid $gray75;
  border-bottom: 1px solid $gray75;
  margin-bottom: 30px;

  .bulk-add {
    button {display: flex;
      align-items: center;
      transition: 0.5s;
      justify-content: center;

      &.refreshed {
        box-shadow: none; // 0 0 5px $black;

        i:before {
          color: $lightGreen;
        }
      }
    }

    i {
      margin: unset;

      &:before {
        transition: .5s;
        color: $white;
        font-size: 25px;
        padding: 0 10px;
      }
    }
  }

  &_bottom {
    margin-top: 30px;
  }

  &_select {
    display: flex;
    align-items: center;
    justify-content: center;

    label {
      font-size: $sm;
      margin-right: 10px;
    }

    .react-select-custom {
      & .react-select__control {
        min-height: 30px;
      }
    }

    .react-select__value-container {
      font-size: $sm;
    }

    .react-select__indicator-separator {
      display: none;
    }

    .react-select__indicator {
      padding: 0;
    }
  }

  &_sort {
    .react-select-custom {
      width: 170px;
    }
  }

  .bulk-add,
  &_select {
    padding: 10px 0;
  }

  &_paginate {
    margin-right: 15px;
    min-width: 100px;
  }
}

@include m-max($sm) {
  .bulk-add {
    justify-content: center;
    width: 100%;

    button {
      margin: 0 auto;
      svg {
        padding-left:5px;
      }
    }
  }
}

@include m-min($sm) {
  .product-list-filter {

    &_paginate,
    .bulk-add {
      @include flex-def;
    }

    &_paginate {
      margin-right: 0;
      justify-content: flex-start;
    }

    &_select {
      padding: 0 15px;
    }

    .bulk-add {
      justify-content: flex-end;
      svg {
        padding-left:5px;
      }
    }

    .bulk-add button {
      max-width: 190px;
    }
  }
}